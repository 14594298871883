.sidebar-title {
    margin-left: 10px;
}
.update-btn {
    width: 25%;
}

.auth-page {
    .custom-login-switch {
        width: 100%;
        height: 40px;
        border: solid 1px gray;
        border-radius: 25px;
        .login-company, .login-staff {
            width: 50%;
            cursor: pointer;
        }
        .login-company span {
            z-index: 1;
        }
        .login-staff span {
            z-index: 1;
        }
        .login-company .switch-slect-company {
            width: 38%;
            height: 33px;
            background-color:#7a7fdc;
            border-radius: 25px;
            margin-left: 5px;
            position: absolute;
        }
        .login-staff .switch-slect-staff {
            width: 38%;
            height: 33px;
            background-color:#7a7fdc;
            border-radius: 25px;
            margin-right: 5px;
            position: absolute;
        }
    }
}   