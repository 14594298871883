//
// _card.scss
//

.card {
  margin-bottom: $grid-gutter-width;
}

.card-drop {
  color: $body-color;
}

.card-title {
  font-size: 15.4px;
  margin: 0 0 7px 0;
  color: #495057;
  font-weight: 600;
}

.card-title-desc {
  color: $card-title-desc;
  margin-bottom: 0;
  font-size: 13px;
}

.card-header-tabs {
  margin-top: -$card-cap-padding-y;
}

.card-header-pills {
  margin: -$card-cap-padding-y / 2;
}

body[data-layout-mode="dark"] {
  .card,
  .card-header,
  .modal-content,
  .offcanvas {
    background-color: $gray-dark-200;
    border-color: lighten($gray-dark-200, 4%);
  }

  .card-title-desc {
    color: $gray-dark-400;
  }
}

.stats-card {
  border-radius: 20px;
  padding: 20px;
  box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 0px rgb(0, 0, 0),
    0.5em 0.5em 1.5em rgba(0, 0, 0, 0.5);
  // height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  p {
    font-size: 18px;
  }
}